import styled from 'styled-components';

import { size, colors, typography } from '@everlywell/leaves';

interface RadioButtonWrapperProps {
  readonly checked: boolean;
}

const RadioGroup = styled.div`
  display: flex;
  padding: ${size.md}px 0 ${size.lg}px;
  gap: ${size.md}px ${size.lg}px;
`;

const RadioButtonWrapper = styled.div<RadioButtonWrapperProps>`
  width: 50%;
  padding: ${size.xs1}px ${size.md}px;
  border: 1px solid
    ${(props) => (props.checked ? colors.green4 : colors.green2)};
  background-color: ${(props) =>
    props.checked ? colors.green1 : 'transparent'};
  box-shadow: 4px 4px 15px -5px rgba(0 0 0 / 10%);
  border-radius: 1px;

  label[for^="biologicalSex"] > div {
    line-height: 2;
  }

  label[for^="biologicalSex"] {
    width: 100%;
  }
`;

const Label = styled.label<{ hasTooltip: boolean; }>`
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.dmSans};
  font-size: ${size.md}px;
  color: ${colors.gray4};
  line-height: 1.5;
  letter-spacing: normal;
  ${({ hasTooltip }) =>
    hasTooltip &&
    `
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: ${size.xs1}px;

    .labelTooltip {
      svg {
        width: calc(${size.md}px + ${size.xs2}px);
        height: calc(${size.md}px + ${size.xs2}px);
        vertical-align: text-top;
      }
    }

    .labelTooltipBox {
      min-width: calc(${size.xl7}px * 2);
    }
  `};
`;


export { RadioGroup, RadioButtonWrapper, Label };
