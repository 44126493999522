import styled from 'styled-components';

import {
  typography,
  colors,
  size,
  mediaQueries,
  Col,
  RadioButton
} from '@everlywell/leaves';

interface RadioButtonWrapperProps {
  readonly checked: boolean;
}

const BillingOption = styled.div<RadioButtonWrapperProps>`
  padding: 10px 0;
  cursor: pointer;
  padding-left: ${size.md}px;
  border: 1px solid
    ${(props) => (props.checked ? colors.green4 : colors.green2)};
  ${(props) =>
    props.checked ? '' : 'box-shadow: 0 1.5px 10px 0 rgba(0, 0, 0, 0.1)'};

  ${mediaQueries.forPhoneOnly} {
    min-height: 80px;
  }
`;

const BillingAddress = styled(Col) <RadioButtonWrapperProps>`
  margin-bottom: ${(props) => (props.checked ? '0' : '28px')};
`;

const Label = styled.label`
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.dmSans};
  font-size: ${size.md}px;
  color: ${colors.gray4};
  line-height: 1.5;
  letter-spacing: normal;
`;

const StyledRadioButton = styled(RadioButton)`
  label {
    &::before {
      width: 20px;
      height: 20px;
    }

    &::after {
      left: 6px;
      width: 10px;
      height: 10px;
    }
  }

  label > div {
    margin-left: calc(27px + ${size.xs2}px);
  }
`;

const ShippingWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 28px;
`;

export {
  BillingOption,
  BillingAddress,
  Label,
  StyledRadioButton,
  ShippingWrapper,
};
